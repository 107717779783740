// import * as firebase from "firebase";
// import "@firebase/auth";
// import "@firebase/firestore";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC9pCKP7I_7LC9uovu4DzJV7frtiOKv8Cc",
  authDomain: "nfteducate.firebaseapp.com",
  projectId: "nfteducate",
  storageBucket: "nfteducate.appspot.com",
  messagingSenderId: "723236622978",
  appId: "1:723236622978:web:fce1ef76e81bca67811a9b",
  measurementId: "G-LWDHC5XEMS"
};

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  export { firebase };